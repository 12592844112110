<div class="black-card" cdkDrag>
  <div class="card-heading" [ngStyle]="{'flex-direction': showTranscript ? 'row' : 'column'}">
    <span class="agent-details" [ngStyle]="{'flex-direction': showTranscript ? 'row' : 'column'}">
      <img [src]="retellService?.liveAgent?.profile_pic ?? '../../../../assets/images/avatar.png'" alt="">
      {{retellService?.liveAgent?.agent_name}}
    </span>
    <span class="hor-1rem">
      <span *ngIf="showTranscript" class="icon">Live Transcript <icon [icon]="'stars'" [size]="'18px'" [iconColor]="'#9333EA'"></icon></span>
      <span class="recording" *ngIf="callStarted">{{ duration | timer }} </span>
    </span>
  </div>
  <div class="card-body w-100" *ngIf="showTranscript">
    <div class="live-call-transcript" #scrollBottom>
      <ng-container *ngFor="let message of transcript">
        <div class="msg-body" [ngClass]="message?.role">
          <div class="transcript-messages">
            <div class="transcript-details user" *ngIf="message?.role === 'agent'">
              <!-- <img src="../../../../../assets/images/avatar.png" alt=""> -->
              <span class="name">{{ retellService?.liveAgent?.agent_name }}</span>
              <span class="icon-grey">
                <img [src]="retellService?.liveAgent?.profile_pic ?? '../../../../assets/images/avatar.png'" uiImageLoader alt="">
              </span>
            </div>
          </div>
          <div class="msg-content">
              <div [innerHTML]="message?.content | boldItalic"></div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="card-footer" *ngIf="callStarted">
    <button (click)="killCall()"><icon [icon]="'call_end'" [size]="'20px'" [iconColor]="'#DC2626'"></icon> Hang up</button>
    <span class="show-transcript" (click)="showTranscript = !showTranscript"><icon [icon]="'transcript'" [size]="'24px'" [iconColor]="'#F3E8FF'"></icon></span>
  </div>
  <div class="call-loader" *ngIf="!callStarted">
    <mat-progress-spinner
      mode="indeterminate"
      diameter="22"
      class="white-progress"
    ></mat-progress-spinner>
  </div>
</div>