import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgOtpInputModule } from 'ng-otp-input';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { InterceptorModule } from './helpers/interceptor.module';
import { AuthGuard } from './helpers/authGuard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { Router } from '@angular/router';
import { OnlineStatusService } from 'ngx-online-status';
import { SharedModule } from './components/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { AngularFireRemoteConfig, AngularFireRemoteConfigModule, SETTINGS } from '@angular/fire/compat/remote-config';
import { GlobalErrorHandler } from './helpers/global-error-handler';


export function appInitializerFactory() {
  return () => {
    return new Promise<void>((resolve) => {
      window.addEventListener('load', () => {
        const loader = document.getElementById('preloader');
        if (loader) {
          setTimeout(() => {
            loader.style.display = 'none'; // Hide the video preloader
          },1000)
        }
        resolve();
      });
    });
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    InterceptorModule,
    FormsModule,
    NgOtpInputModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SlickCarouselModule,
    BrowserAnimationsModule,
    LoggerModule.forRoot({
      serverLoggingUrl:
        'https://kyhvp5hso2.execute-api.ap-south-1.amazonaws.com/prod/logger',
      customHttpHeaders: new HttpHeaders({
        'x-api-key': '5JTGZXVkvy4EG0WziQR3N4NILWMOucYT7MN8o2RA',
      }),
      level: NgxLoggerLevel.INFO,
      serverLogLevel: NgxLoggerLevel.INFO,
      disableConsoleLogging: true
    }),
    SharedModule,
    NgbModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireRemoteConfigModule
  ],
  providers: [
    AuthGuard,
    OnlineStatusService,
    {
      provide: SETTINGS, useFactory: () => !environment.production ? { minimumFetchIntervalMillis: 10_000 } : {}
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private remoteConfig: AngularFireRemoteConfig) {
    this.remoteConfig.fetchAndActivate();
   }
}
